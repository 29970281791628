import {
  action, makeObservable, observable, runInAction,
} from 'mobx';
import HomePageViewModel from './HomePageViewModel';
import DownloadDataUsecase from './usecase/download_data/DownloadDataUsecase';
import DownloadableDataImpl from './usecase/download_data/DownloadableDataImpl';

class HomePageInteractor {
  viewState: HomePageViewModel;

  private currentDownloadId: string | undefined;

  private downloadUsecase: DownloadDataUsecase | undefined;

  constructor() {
    this.viewState = new HomePageViewModel(new DownloadableDataImpl(undefined, undefined));

    makeObservable(
      this,
      {
        viewState: observable,
        reload: action,
      },
    );
  }

  reload() {
    if (this.downloadUsecase !== undefined) {
      this.downloadUsecase.cancel();
    }

    runInAction(() => {
      this.viewState = new HomePageViewModel(new DownloadableDataImpl(undefined, undefined));
    });

    this.downloadUsecase = new DownloadDataUsecase(
      this.currentDownloadId,
      (data) => {
        runInAction(() => {
          this.viewState = new HomePageViewModel(new DownloadableDataImpl(data, undefined));
        });
      },
      (error) => {
        runInAction(() => {
          this.viewState = new HomePageViewModel(new DownloadableDataImpl(undefined, error));
        });
      },
    );
  }

  setDownloadId(downloadId: string | undefined) {
    if (this.currentDownloadId === downloadId) {
      return;
    }

    this.currentDownloadId = downloadId;

    this.reload();
  }
}

const homePageInteractor = new HomePageInteractor();

export default homePageInteractor;

export {
  HomePageInteractor,
};
