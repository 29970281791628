import React from 'react';
import { Typography, styled } from '@mui/material';

type TabId = string | number | any;

interface TabData {
  id: TabId;
  title: string;
}

interface TabsViewProps {
  selectedId: TabId;
  setSelectedId: (id: TabId) => void;
  data: TabData[];
  disabled: boolean;
}

const TabsViewContainerDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  padding: '6px',
}));

function TabsViewContainer(props: { children: any }) {
  const { children } = props;
  return (
    <TabsViewContainerDiv>
      {children}
    </TabsViewContainerDiv>
  );
}

const TabViewDiv = styled('div')(() => ({
  borderRadius: '12px',
  padding: '12px',
  width: '100%',
  align: 'center',
}));

const TabViewSelectedDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.selected,
  borderRadius: '12px',
  padding: '12px',
  width: '100%',
  align: 'center',
}));

function TabView(props: { isSelected: boolean, title: string, onClick: () => void }) {
  const { isSelected, title, onClick } = props;
  if (isSelected) {
    return (
      <TabViewSelectedDiv>
        <Typography variant="body1" style={{ textAlign: 'center', cursor: 'pointer' }}>{title}</Typography>
      </TabViewSelectedDiv>
    );
  }
  return (
    <TabViewDiv onClick={() => onClick()}>
      <Typography variant="body1" style={{ textAlign: 'center', cursor: 'pointer' }}>{title}</Typography>
    </TabViewDiv>
  );
}

function TabsView(props: TabsViewProps) {
  const {
    data, selectedId, setSelectedId, disabled,
  } = props;
  return (
    <TabsViewContainer>
      {data.map((elem) => (
        <TabView
          key={JSON.stringify(elem)}
          isSelected={elem.id === selectedId}
          title={elem.title}
          onClick={() => {
            if (!disabled) {
              setSelectedId(elem.id);
            }
          }}
        />
      ))}
    </TabsViewContainer>
  );
}

export default TabsView;

export type {
  TabId,
  TabData,
  TabsViewProps,
};
