import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container, Typography, Button, Grid, Alert,
} from '@mui/material';
import { observer } from 'mobx-react';
import urlsProvider from '../../env/UrlsProvider';
import openLink from '../../utils/link/openLink';
import EmptyPageViewModel, {
  EmptyPageHeaderItem, EmptyPageItem, EmptyPageQrCodeItem, EmptyPageSupportItem,
} from './EmptyPageViewModel';
import emptyPageInteractor, { EmptyPageInteractor } from './EmptyPageInteractor';
import { adaptationToSchema, getLinkAdaptation } from '../../utils/link/link_utils';
import CustomPaper from '../../components/paper/CustomPaper';

function SupportBottomItemView() {
  return (
    <Button
      variant="text"
      size="large"
      color="info"
      onClick={() => openLink(urlsProvider.supportUrl)}
    >
      Помощь
    </Button>
  );
}

function NewEmptyPageTitleCard() {
  const navigate = useNavigate();
  return (
    <CustomPaper elevation={2}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid
            container
            spacing={2}
            direction="column"
            style={{
              paddingLeft: '32px',
              paddingRight: '32px',
              paddingTop: '24px',
              paddingBottom: '24px',
            }}
          >
            <Grid item>
              <Typography variant="h2">Hyperlinks Helper</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Чтобы начать перейдите в телеграм бота
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => openLink(urlsProvider.botUrl)}
              >
                Перейти
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CustomPaper variant="outlined">
            <Grid
              container
              direction="column"
              spacing={2}
              alignItems="center"
              style={{
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingTop: '16px',
                paddingBottom: '16px',
              }}
            >
              <Grid item>
                <Typography variant="h5">
                  Или можно просто создать QR код для интента
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  size="medium"
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate('/just_qr')}
                >
                  Создать QR код
                </Button>
              </Grid>
            </Grid>
          </CustomPaper>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

function EmptyPageQrCodeSummaryView(props: { item: EmptyPageQrCodeItem }) {
  const { item } = props;

  const imageUrl = `${urlsProvider.qrBackendBaseUrl}/v1/qr_code_for_intent/`
    + `${adaptationToSchema(getLinkAdaptation(item.intent))?.replace('://', '')}`
    + '?redirectionAvailable=true'
    + `&intent=${encodeURIComponent(item.intent)}`;

  return (
    <CustomPaper>
      <Grid container direction="row" style={{ padding: 0 }}>
        <Grid item>
          <CustomPaper
            variant="outlined"
            style={{
              overflow: 'hidden',
              padding: 0,
              margin: 0,
              width: '128px',
              height: '128px',
            }}
          >
            <img width="126px" height="126px" src={imageUrl} alt="QR code" />
          </CustomPaper>
        </Grid>
        <Grid item>
          <Grid container direction="column" style={{ padding: '16px' }} alignContent="center">
            <Grid item>
              <Typography variant="body1" style={{ wordBreak: 'break-all' }}>
                {item.intent}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {`Генераций: ${item.generations}`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {`Переадресаций: ${item.redirections}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

function EmptyPageItemImpl(props: { item: EmptyPageItem }) {
  const { item } = props;

  if (item instanceof EmptyPageHeaderItem) {
    return <NewEmptyPageTitleCard />;
  }

  if (item instanceof EmptyPageSupportItem) {
    return <SupportBottomItemView />;
  }

  if (item instanceof EmptyPageQrCodeItem) {
    return (
      <EmptyPageQrCodeSummaryView item={item} />
    );
  }

  return (
    <Alert severity="error">
      Unknown item
      {JSON.stringify(item)}
    </Alert>
  );
}

function EmptyPageContent(props: { viewModel: EmptyPageViewModel }) {
  const { viewModel } = props;
  const { items } = viewModel;

  return (
    <Grid container direction="column" spacing={4}>
      { items.map((item) => (
        <Grid item key={JSON.stringify(item)}>
          <EmptyPageItemImpl item={item} />
        </Grid>
      ))}
    </Grid>
  );
}

const EmptyPageImpl = observer((props: { interactor: EmptyPageInteractor }) => {
  const { interactor } = props;
  const { viewModel } = interactor;

  return (
    <EmptyPageContent viewModel={viewModel} />
  );
});

function EmptyPage() {
  return (
    <Container>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '32px',
      }}
      >
        <EmptyPageImpl interactor={emptyPageInteractor} />
      </div>
    </Container>
  );
}

export default EmptyPage;
