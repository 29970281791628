enum ApplicationLinkAdaptation {
  YandexMaps,
  NaviMaps,
  Yango,
  None,
}

const adaptationToSchema = (adaptation: ApplicationLinkAdaptation): string | undefined => {
  switch (adaptation) {
    case ApplicationLinkAdaptation.YandexMaps: return 'yandexmaps://';
    case ApplicationLinkAdaptation.NaviMaps: return 'yandexnavi://';
    case ApplicationLinkAdaptation.Yango: return 'yangomaps://';
    case ApplicationLinkAdaptation.None:
    default: return undefined;
  }
};

const getLinkAdaptation = (intent: string): ApplicationLinkAdaptation => {
  if (intent.startsWith('yandexmaps://')) {
    return ApplicationLinkAdaptation.YandexMaps;
  }
  if (intent.startsWith('yandexnavi://')) {
    return ApplicationLinkAdaptation.NaviMaps;
  }
  if (intent.startsWith('yangomaps://')) {
    return ApplicationLinkAdaptation.Yango;
  }
  return ApplicationLinkAdaptation.None;
};

const adaptLinkFor = (urlString: string, to: ApplicationLinkAdaptation) => {
  switch (to) {
    case ApplicationLinkAdaptation.YandexMaps:
      return urlString
        .replace('yandexnavi://', 'yandexmaps://')
        .replace('yangomaps://', 'yandexmaps://');
    case ApplicationLinkAdaptation.NaviMaps:
      return urlString
        .replace('yandexmaps://', 'yandexnavi://')
        .replace('yangomaps://', 'yandexnavi://');
    case ApplicationLinkAdaptation.Yango:
      return urlString
        .replace('yandexmaps://', 'yangomaps://')
        .replace('yandexnavi://', 'yangomaps://');
    case ApplicationLinkAdaptation.None:
    default:
      return urlString;
  }
};

export {
  ApplicationLinkAdaptation,
  getLinkAdaptation,
  adaptLinkFor,
  adaptationToSchema,
};
