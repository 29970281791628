import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import logo_1_1 from '../../icons/logo/logo_1_1.svg';
import logo_1_2 from '../../icons/logo/logo_1_2.svg';
import logo_1_3 from '../../icons/logo/logo_1_3.svg';
import logo_1_4 from '../../icons/logo/logo_1_4.svg';
import logo_2_1 from '../../icons/logo/logo_2_1.svg';
import logo_2_2 from '../../icons/logo/logo_2_2.svg';
import logo_2_3 from '../../icons/logo/logo_2_3.svg';
import logo_2_4 from '../../icons/logo/logo_2_4.svg';
import logo_3_1 from '../../icons/logo/logo_3_1.svg';
import logo_3_2 from '../../icons/logo/logo_3_2.svg';
import logo_3_3 from '../../icons/logo/logo_3_3.svg';
import logo_3_4 from '../../icons/logo/logo_3_4.svg';
import logo_4_1 from '../../icons/logo/logo_4_1.svg';
import logo_4_2 from '../../icons/logo/logo_4_2.svg';
import logo_4_3 from '../../icons/logo/logo_4_3.svg';
import logo_4_4 from '../../icons/logo/logo_4_4.svg';
import './logo.css';

const randomInt = (max: number) => Math.floor(Math.random() * max);

const logos = [
  logo_1_1,
  logo_1_2,
  logo_1_3,
  logo_1_4,

  logo_2_1,
  logo_2_2,
  logo_2_3,
  logo_2_4,

  logo_3_1,
  logo_3_2,
  logo_3_3,
  logo_3_4,

  logo_4_1,
  logo_4_2,
  logo_4_3,
  logo_4_4,
];

function LogoIcon() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleClick}
      style={{ padding: '12px', position: 'relative' }}
      role="presentation"
    >
      <img
        src={logos[randomInt(logos.length)]}
        alt="logo"
        width="64px"
        style={{ cursor: 'pointer' }}
        className="pulsating_logo"
      />
      <Typography
        style={{
          position: 'absolute',
          left: '64px',
          bottom: '5px',
          fontSize: '0.5em',
        }}
      >
        {process.env.REACT_APP_VERSION ?? 'ERROR'}
      </Typography>
    </div>
  );
}

export default LogoIcon;
