import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {
  Alert, CircularProgress, Container, Grid,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { DownloadingError } from './HomePageViewModel';
import homePageInteractor, { HomePageInteractor } from './HomePageInteractor';
import ContentView from './views/ContentView';
import GoToBotCard from '../common/components/go_to_bot_card/GoToBotCard';

function ErrorView(props: { error: DownloadingError }) {
  const { error } = props;
  return (
    <Alert severity="error">{error.message}</Alert>
  );
}

const HomePageImpl = observer((props: { interactor: HomePageInteractor }) => {
  const { viewState } = props.interactor;

  const { data } = viewState;

  const { id } = useParams();

  useEffect(() => { props.interactor.setDownloadId(id); });

  if (data.downloaded !== undefined) {
    return (
      <ContentView data={data.downloaded} onReloadClick={() => props.interactor.reload()} />
    );
  }

  if (data.error !== undefined) {
    return (
      <ErrorView error={data.error} />
    );
  }

  return (
    <CircularProgress size={128} />
  );
});

function HomePage() {
  return (
    <Container sx={{ paddingTop: '24px', paddingBottom: '32px' }}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <HomePageImpl interactor={homePageInteractor} />
        </Grid>
        <Grid item>
          <GoToBotCard />
        </Grid>
      </Grid>
    </Container>
  );
}

export default HomePage;
