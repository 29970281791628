import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import ym from 'react-yandex-metrika';
import { DownloadedData, DownloadingError } from '../../HomePageViewModel';
import ButtonInfo from '../../data/ButtonInfo';
import urlsProvider from '../../../../env/UrlsProvider';

class DownloadDataUsecase {
  private abortController = new AbortController();

  constructor(
    downloadId: string | undefined,
    onOk: ((data: DownloadedData<ButtonInfo[]>) => void),
    onError: ((error: DownloadingError) => void),
  ) {
    const requestUuid = uuidv4();
    ym('requestLinks', { status: 'starting', id: downloadId });
    axios.get(`${urlsProvider.hostDataUrl}${downloadId ?? 'example'}.json?req=${requestUuid}`, { signal: this.abortController.signal })
      .then((data) => data.data)
      .then((data) => {
        const rows: ButtonInfo[] = [];
        if (Array.isArray(data)) {
          data.forEach((elem) => {
            if ('link' in elem && 'title' in elem) {
              rows.push({ link: elem.link, title: elem.title });
            } else {
              ym('requestLinksParsingError', { data: JSON.stringify(elem) });
            }
          });
          ym('requestLinks', { status: 'success', id: downloadId, countLinks: rows.length });
          onOk({ value: rows });
        } else {
          ym('requstLinks', { status: 'error', reason: `Wrong schema for id: ${downloadId}`, id: downloadId });
          onError({ message: `Чет скачалась какая-то лажа: ${JSON.stringify(data)}` });
        }
      })
      .catch((error) => {
        onError({ message: JSON.stringify(error) });
      });
  }

  cancel() {
    this.abortController.abort();
  }
}

export default DownloadDataUsecase;
