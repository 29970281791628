import axios from 'axios';
import { makeObservable, observable, runInAction } from 'mobx';
import urlsProvider from '../../env/UrlsProvider';

interface IntentSummary {
  intent: string;
  generations: number;
  redirections: number;
}

class IntentSummaryLoadingUsecase {
  result: IntentSummary[] | null;

  error: string | null;

  constructor() {
    this.result = null;
    this.error = null;

    makeObservable(
      this,
      {
        result: observable,
        error: observable,
      },
    );
  }

  load() {
    axios.post(`${urlsProvider.qrBackendBaseUrl}/v1/counter/summary`, { count: 10, policy: 'ByGenerations' })
      .then((data) => data.data)
      .then((data) => {
        runInAction(() => { this.result = data; });
      })
      .catch((error) => {
        runInAction(() => { this.error = JSON.stringify(error); });
      });
  }
}

export default IntentSummaryLoadingUsecase;

export type {
  IntentSummary,
};
