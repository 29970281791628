function getAndVerify(key: string): string {
  const result = process.env[key];
  if (result === undefined) {
    // eslint-disable-next-line
    console.error(`process.env.${key} is empty!`);
    return '';
  }
  return result;
}

class UrlsProvider {
  botUrl: string;

  supportUrl: string;

  hostDataUrl: string;

  qrBackendBaseUrl: string;

  constructor() {
    this.botUrl = getAndVerify('REACT_APP_BOT_URL');
    this.supportUrl = getAndVerify('REACT_APP_SUPPORT_URL');
    this.hostDataUrl = getAndVerify('REACT_APP_HOST_DATA_DIR');
    this.qrBackendBaseUrl = getAndVerify('REACT_APP_QR_BACKEND_BASE_URL');
  }
}

const urlsProvider = new UrlsProvider();

export default urlsProvider;

export {
  UrlsProvider,
};
