import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';
import { rootLocalStorageAdapter } from '../../utils/local_storage/LocalStorageAdapter';

function ThemeSwitcherContainer(props: { children: any, onSwitch: () => void }) {
  const { children, onSwitch } = props;
  return (
    <IconButton size="large" onClick={() => onSwitch()}>
      {children}
    </IconButton>
  );
}

function ThemeSwitcherView() {
  const [isLightMode, setIsLightMode] = useState(rootLocalStorageAdapter.scoping('theme').getString('is_dark_mode') === 'light');

  const onSwitch = () => {
    if (isLightMode) {
      rootLocalStorageAdapter.scoping('theme').putString('is_dark_mode', 'dark');
    } else {
      rootLocalStorageAdapter.scoping('theme').putString('is_dark_mode', 'light');
    }
    setIsLightMode(!isLightMode);
    window.location.reload();
  };

  return (
    <ThemeSwitcherContainer onSwitch={onSwitch}>
      {!isLightMode && <DarkMode fontSize="large" />}
      {isLightMode && <LightMode fontSize="large" />}
    </ThemeSwitcherContainer>
  );
}

export default ThemeSwitcherView;
