import {
  action, makeObservable, observable, observe,
} from 'mobx';
import EmptyPageViewModel, {
  EmptyPageHeaderItem, EmptyPageItem, EmptyPageQrCodeItem, EmptyPageSupportItem,
} from './EmptyPageViewModel';
import IntentSummaryLoadingUsecase from './IntentSummaryLoadingUsecase';

class EmptyPageInteractor {
  viewModel: EmptyPageViewModel;

  intentSummaryLoadingUseCase: IntentSummaryLoadingUsecase;

  constructor() {
    this.viewModel = new EmptyPageViewModel([]);
    this.intentSummaryLoadingUseCase = new IntentSummaryLoadingUsecase();

    makeObservable(
      this,
      {
        viewModel: observable,
        updateViewModel: action,
      },
    );

    this.init();
  }

  init() {
    this.updateViewModel();
    this.loadIntentsSummary();
  }

  updateViewModel() {
    const items: EmptyPageItem[] = [];
    items.push(new EmptyPageHeaderItem());

    const summaryResult = this.intentSummaryLoadingUseCase.result;
    if (summaryResult !== null) {
      summaryResult?.forEach((summaryItem) => {
        items.push(
          new EmptyPageQrCodeItem(
            summaryItem.intent,
            summaryItem.generations,
            summaryItem.redirections,
          ),
        );
      });
    }
    items.push(new EmptyPageSupportItem());

    this.viewModel = new EmptyPageViewModel(items);
  }

  loadIntentsSummary() {
    this.intentSummaryLoadingUseCase.load();
    observe(this.intentSummaryLoadingUseCase, () => {
      this.updateViewModel();
    });
  }
}

const emptyPageInteractor = new EmptyPageInteractor();
export default emptyPageInteractor;

export {
  EmptyPageInteractor,
};
