import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Container, Grid, IconButton, InputBase, Paper, Skeleton, Typography,
} from '@mui/material';
import { ArrowForward, Restore } from '@mui/icons-material';
import TabsView, { TabData } from '../../components/tabs/TabsView';
import {
  ApplicationLinkAdaptation, adaptLinkFor, adaptationToSchema, getLinkAdaptation,
} from '../../utils/link/link_utils';
import urlsProvider from '../../env/UrlsProvider';
import useLocalStoredState from '../../utils/local_state/useLocalStoredState';

function HeaderView() {
  return (
    <Paper sx={{ p: 3 }} elevation={4}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h6">Создать QR код для интента</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            Чтобы создать QR для интента нужно просто ввести его ниже
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            Также можно выбрать для какого приложения будет создан интент
            <br />
            <sub>
              (Также можно переключать приложение
              и после выбора интента - в этом случае просто поменяется схема)
            </sub>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const tabsData: TabData[] = [
  {
    id: ApplicationLinkAdaptation.YandexMaps,
    title: 'Карты',
  },
  {
    id: ApplicationLinkAdaptation.NaviMaps,
    title: 'НавиКарты',
  },
  {
    id: ApplicationLinkAdaptation.Yango,
    title: 'Yango',
  },
];

function QrCodeCreationView(props: { link: string, useRedirection: boolean, restore: () => void }) {
  const { link, restore, useRedirection } = props;

  const [loading, setLoading] = useState(true);
  const [hasErrorWhileLoading, setErrorWhileLoading] = useState(false);

  const adaptation = getLinkAdaptation(link);

  if (adaptation === ApplicationLinkAdaptation.None) {
    return (
      <Alert severity="error">
        Нельзя создать QR код для интента с не правильной схемой
      </Alert>
    );
  }

  const imageUrl = `${urlsProvider.qrBackendBaseUrl}/v1/qr_code_for_intent/`
    + `${adaptationToSchema(adaptation)?.replace('://', '')}`
    + '?countMe=true'
    + `&redirectionAvailable=${JSON.stringify(useRedirection)}`
    + `&intent=${encodeURIComponent(link)}`;

  return (
    <Grid container direction="column" spacing={2} alignItems="center">
      {loading
      && (
        <Grid item>
          <Skeleton
            width={256}
            height={256}
            variant="rectangular"
            animation="wave"
          />
        </Grid>
      )}
      <Grid item>
        <img
          width={256}
          height={256}
          src={imageUrl}
          style={{ display: loading || hasErrorWhileLoading ? 'none' : 'block' }}
          alt={imageUrl}
          onError={() => { setErrorWhileLoading(true); setLoading(false); }}
          onLoad={() => setLoading(false)}
        />
      </Grid>
      {hasErrorWhileLoading
      && (
        <Grid item>
          <Alert severity="error">
            Что-то пошло не так при генерации QR кода
          </Alert>
        </Grid>
      )}
      <Grid item>
        <Button
          variant="outlined"
          onClick={() => restore()}
          endIcon={<Restore />}
        >
          Сгенерировать еще
        </Button>
      </Grid>
    </Grid>
  );
}

function CreateQrPage() {
  const [currentIntent, setCurrentIntent] = useState<string>('');
  const [selectedLinkAdaptation, setSelectedLinkAdaptation] = useState<ApplicationLinkAdaptation>(
    ApplicationLinkAdaptation.None,
  );
  const [isCreatingQrCode, setIsCreatingQrCode] = useState(false);
  const [useRedirection, setUseRedirection] = useLocalStoredState('create_qr_page', 'useRedirection', false);

  const updateCurrentIntentValue = (newValue: string) => {
    setCurrentIntent(newValue);
    const newAdaptation = getLinkAdaptation(newValue);
    if (newAdaptation !== selectedLinkAdaptation) {
      setSelectedLinkAdaptation(newAdaptation);
    }
  };

  const updateSelectedLinkAdaptation = (adaptation: ApplicationLinkAdaptation) => {
    const newLink = adaptLinkFor(currentIntent, adaptation);
    if (newLink !== currentIntent) {
      setCurrentIntent(newLink);
      setSelectedLinkAdaptation(adaptation);
    }
  };

  const processQrCodeCreation = () => {
    setIsCreatingQrCode(true);
  };

  const endButton = () => (
    <IconButton
      size="large"
      color="primary"
      onClick={processQrCodeCreation}
      disabled={isCreatingQrCode || selectedLinkAdaptation === ApplicationLinkAdaptation.None}
    >
      <ArrowForward />
    </IconButton>
  );

  return (
    <Container sx={{ mt: 4 }}>
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <HeaderView />
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 1, pl: 3 }}>
            <InputBase
              value={currentIntent}
              placeholder="Например yandexmaps://add_exp?some_exp"
              fullWidth
              endAdornment={endButton()}
              onChange={(event) => updateCurrentIntentValue(event.target.value || '')}
              disabled={isCreatingQrCode}
            />
          </Paper>
        </Grid>

        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <TabsView
                data={tabsData}
                selectedId={selectedLinkAdaptation}
                setSelectedId={(id) => updateSelectedLinkAdaptation(id)}
                disabled={isCreatingQrCode}
              />
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item>
                  <Checkbox
                    checked={useRedirection}
                    onChange={() => setUseRedirection(!useRedirection)}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    Использовать переадресацию при создании интента
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {isCreatingQrCode
        && (
          <Grid item>
            <QrCodeCreationView
              link={currentIntent}
              restore={() => setIsCreatingQrCode(false)}
              useRedirection={useRedirection}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default CreateQrPage;
