import React, { useState } from 'react';
import {
  Grid, Button, Typography, Modal, Paper, Skeleton, Container, IconButton,
} from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import { Close, QrCode2Rounded } from '@mui/icons-material';
import ym from 'react-yandex-metrika';
import ButtonInfo from '../data/ButtonInfo';
import {
  ApplicationLinkAdaptation, adaptLinkFor, adaptationToSchema, getLinkAdaptation,
} from '../../../utils/link/link_utils';
import urlsProvider from '../../../env/UrlsProvider';
import openLink from '../../../utils/link/openLink';

enum LinkViewStyle {
  Compact,
  Detailed,
}

function QrCodeImageDrawer(props: { link: string, adaptation: ApplicationLinkAdaptation }) {
  const { link, adaptation } = props;

  const [isLoading, setIsLoading] = useState(true);

  const imageUrl = `${urlsProvider.qrBackendBaseUrl}/v1/qr_code_for_intent/`
    + `${adaptationToSchema(adaptation)?.replace('://', '')}`
    + '?countMe=true'
    + `&intent=${encodeURIComponent(link)}`;

  return (
    <>
      <img
        src={imageUrl}
        alt={imageUrl}
        width={256}
        height={256}
        onLoad={() => setIsLoading(false)}
        style={{ display: isLoading ? 'none' : 'block' }}
      />

      {isLoading
      && (
        <Skeleton
          width={256}
          height={245}
        />
      )}
    </>
  );
}

function GetQrButtonView(props: { buttonInfo: ButtonInfo }) {
  const { buttonInfo } = props;
  const adaptation = getLinkAdaptation(buttonInfo.link);

  const [modalOpened, setModalOpenedState] = useState(false);

  const setModalOpened = (newValue: boolean) => {
    setModalOpenedState(newValue);
    ym('getQrCodeModalStateChanged', { opened: newValue });
  };

  if (adaptation === ApplicationLinkAdaptation.None) {
    return null;
  }

  return (
    <>
      <Modal
        open={modalOpened}
        onClose={() => setModalOpened(false)}
      >
        <Container>
          <Paper
            elevation={8}
            sx={{
              p: 2,
              pb: 4,
              m: 'auto',
              mt: 10,
              maxWidth: '384px',
            }}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">QR-код</Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setModalOpened(false)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item container direction="row" justifyContent="space-around">
                <Grid item>
                  <QrCodeImageDrawer
                    link={buttonInfo.link}
                    adaptation={adaptation}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Modal>
      <IconButton onClick={() => setModalOpened(!modalOpened)}>
        <QrCode2Rounded fontSize="large" />
      </IconButton>
    </>
  );
}

function CompactLinkView(props: { buttonInfo: ButtonInfo }) {
  const { buttonInfo } = props;

  return (
    <Grid container direction="row" spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <Button variant="contained" size="large" onClick={() => openLink(buttonInfo.link)}>
          {buttonInfo.title}
        </Button>
      </Grid>
      <Grid item>
        <GetQrButtonView buttonInfo={buttonInfo} />
      </Grid>
    </Grid>
  );
}

enum ColoredUrlPathType {
  Error,
  Protocol,
  Host,
  SearchParam,
}

function ColoredUrlPath(props: {
  type: ColoredUrlPathType,
  value: string,
}) {
  const { type, value } = props;

  const color = (() => {
    switch (type) {
      case ColoredUrlPathType.Error: return red.A400;
      case ColoredUrlPathType.Protocol: return red[400];
      case ColoredUrlPathType.Host: return blue[600];
      case ColoredUrlPathType.SearchParam: return green[400];
      default:
        return red.A400;
    }
  })();

  return (
    <Typography
      variant="body2"
      align="center"
      style={{
        backgroundColor: color,
        padding: '4px',
        borderRadius: '4px',
      }}
    >
      {value}
    </Typography>
  );
}

const LinkExplainView = (props: { urlString: string }) => {
  try {
    const url = new URL(props.urlString);

    return (
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <ColoredUrlPath type={ColoredUrlPathType.Protocol} value={url.protocol} />
        </Grid>
        { url.hostname.length > 0
                    && (
                    <Grid item>
                      <ColoredUrlPath type={ColoredUrlPathType.Host} value={url.hostname} />
                    </Grid>
                    )}
        { Array.from(url.searchParams.entries()).map((elem) => (
          <Grid item key={JSON.stringify(elem)}>
            <ColoredUrlPath
              type={ColoredUrlPathType.SearchParam}
              value={`${elem[0]}=${elem[1]}`}
            />
          </Grid>
        )) }
      </Grid>
    );
  } catch (error) {
    return (
      <ColoredUrlPath type={ColoredUrlPathType.Error} value={props.urlString} />
    );
  }
};

function DetailedLinkView(props: { buttonInfo: ButtonInfo }) {
  const { buttonInfo } = props;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">
            {buttonInfo.title}
          </Typography>
        </Grid>
        <Grid item>
          <LinkExplainView urlString={buttonInfo.link} />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing={1} alignItems="center">
        <Grid item>
          <Button variant="contained" size="small" onClick={() => openLink(buttonInfo.link)}>
            Открыть
          </Button>
        </Grid>
        <Grid item>
          <GetQrButtonView buttonInfo={buttonInfo} />
        </Grid>
      </Grid>
    </Grid>
  );
}

interface LinkViewProps {
  buttonInfo: ButtonInfo;
  style: LinkViewStyle;
  adaptation: ApplicationLinkAdaptation;
}

function LinkView(props: LinkViewProps) {
  const { buttonInfo, style, adaptation } = props;

  const adaptedButtonInfo: ButtonInfo = {
    title: buttonInfo.title,
    link: adaptLinkFor(buttonInfo.link, adaptation),
  };

  switch (style) {
    case LinkViewStyle.Detailed:
      return <DetailedLinkView buttonInfo={adaptedButtonInfo} />;
    case LinkViewStyle.Compact:
    default:
      return <CompactLinkView buttonInfo={adaptedButtonInfo} />;
  }
}

export default LinkView;

export {
  LinkViewStyle,
};
