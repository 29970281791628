import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import EmptyPage from './pages/empty/EmptyPage';
import DemoPage from './pages/demo/DemoPage';
import HeaderView from './components/header/HeaderView';
import CreateQrPage from './pages/qr/CreateQrPage';

function Root() {
  return (
    <HashRouter>
      <HeaderView />
      <Routes>
        { process.env.NODE_ENV === 'development' && <Route path="/demo" element={<DemoPage />} /> }
        <Route path="/just_qr" element={<CreateQrPage />} />
        <Route path="/:id" element={<HomePage />} />
        <Route path="/" element={<EmptyPage />} />
      </Routes>
    </HashRouter>
  );
}

export default Root;
