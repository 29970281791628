class EmptyPageItem { }

class EmptyPageHeaderItem implements EmptyPageItem { }

class EmptyPageSupportItem implements EmptyPageItem { }

class EmptyPageQrCodeItem implements EmptyPageItem {
  intent: string;

  generations: number;

  redirections: number;

  constructor(intent: string, generations: number, redirections: number) {
    this.intent = intent;
    this.generations = generations;
    this.redirections = redirections;
  }
}

export default class EmptyPageViewModel {
  items: EmptyPageItem[];

  constructor(items: EmptyPageItem[]) {
    this.items = items;
  }
}

export {
  EmptyPageItem,
  EmptyPageHeaderItem,
  EmptyPageSupportItem,
  EmptyPageQrCodeItem,
};
