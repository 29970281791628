import React from 'react';
import {
  Container, Grid,
} from '@mui/material';
import LogoIcon from '../logo/Logo';
import ThemeSwitcherView from '../theme_switcher/ThemeSwitcherView';
import CustomPaper from '../paper/CustomPaper';

function HeaderView() {
  return (
    <Container sx={{ marginTop: '24px' }}>
      <CustomPaper elevation={2} sx={{ padding: 1 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <LogoIcon />
          </Grid>
          <Grid item>
            <ThemeSwitcherView />
          </Grid>
        </Grid>
      </CustomPaper>
    </Container>
  );
}

export default HeaderView;
