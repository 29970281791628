import ButtonInfo from './data/ButtonInfo';

interface DownloadedData<T> {
  value: T;
}

interface DownloadingError {
  message: string;
}

interface DownloadableData<T> {
  downloaded: DownloadedData<T> | undefined;
  error: DownloadingError | undefined;
}

class HomePageViewModel {
  data: DownloadableData<ButtonInfo[]>;

  constructor(data: DownloadableData<ButtonInfo[]>) {
    this.data = data;
  }
}

export default HomePageViewModel;

export type {
  DownloadedData,
  DownloadingError,
  DownloadableData,
};
