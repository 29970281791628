import { DownloadableData, DownloadedData, DownloadingError } from '../../HomePageViewModel';

class DownloadableDataImpl<T> implements DownloadableData<T> {
  downloaded: DownloadedData<T> | undefined;

  error: DownloadingError | undefined;

  constructor(downloaded: DownloadedData<T> | undefined, error: DownloadingError | undefined) {
    this.downloaded = downloaded;
    this.error = error;
  }
}

export default DownloadableDataImpl;
