import { useState } from 'react';
import { rootLocalStorageAdapter } from '../local_storage/LocalStorageAdapter';

function useLocalStoredState<T>(
  viewName: string,
  param: string,
  defaultValue: T,
): [T, (newValue: T) => void] {
  const adapter = rootLocalStorageAdapter
    .scoping('local_stored_state')
    .scoping(viewName);

  const storedValue = adapter.getString(param);
  const stateValue = storedValue === null ? defaultValue : JSON.parse(storedValue);

  const [state, setState] = useState<T>(stateValue);

  const setStateImpl = (newData: T) => {
    setState(newData);
    adapter.putString(param, JSON.stringify(newData));
  };

  return [
    state,
    setStateImpl,
  ];
}

export default useLocalStoredState;
