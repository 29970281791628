class LocalStorageAdapter {
  private scope: string;

  private storage: Storage;

  constructor(scope: string) {
    this.scope = scope;
    this.storage = window.localStorage;
  }

  scoping(innerScope: string) {
    return new LocalStorageAdapter(`${this.scope}.${innerScope}`);
  }

  getString(key: string): string | null {
    return this.storage.getItem(`${this.scope}.${key}`);
  }

  putString(key: string, value: string) {
    this.storage.setItem(`${this.scope}.${key}`, value);
  }

  clearString(key: string) {
    this.storage.removeItem(`${this.scope}.${key}`);
  }
}

export default LocalStorageAdapter;

const rootLocalStorageAdapter = new LocalStorageAdapter('v1');

export {
  rootLocalStorageAdapter,
};
