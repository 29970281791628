import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { YMInitializer } from 'react-yandex-metrika';
import Root from './Root';
import { rootLocalStorageAdapter } from './utils/local_storage/LocalStorageAdapter';

const theme = createTheme({
  palette: {
    mode: rootLocalStorageAdapter.scoping('theme').getString('is_dark_mode') === 'light' ? 'light' : 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <YMInitializer
        accounts={[95345322]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />
      <CssBaseline />
      <Root />
    </ThemeProvider>
  );
}

export default App;
