import React from 'react';
import {
  Button, Grid, Typography,
} from '@mui/material';
import urlsProvider from '../../../../env/UrlsProvider';
import openLink from '../../../../utils/link/openLink';
import CustomPaper from '../../../../components/paper/CustomPaper';

function GoToBotCard() {
  return (
    <CustomPaper>
      <Grid container direction="column" spacing={2} style={{ paddingLeft: '16px', paddingRight: '16px', paddingBottom: '8px' }}>
        <Grid item>
          <Typography variant="h6">Чтобы добавить еще ссылок нужно перейти в бота</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="text"
            onClick={() => openLink(urlsProvider.botUrl)}
          >
            В бота
          </Button>
        </Grid>
      </Grid>
    </CustomPaper>
  );
}

export default GoToBotCard;
