import React, { useState } from 'react';
import {
  Button, Grid, IconButton, Switch, Typography,
} from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { Settings } from '@mui/icons-material';
import { DownloadedData } from '../HomePageViewModel';
import LinkView, { LinkViewStyle } from './LinkView';
import TabsView, { TabId, TabsViewProps } from '../../../components/tabs/TabsView';
import useLocalStoredState from '../../../utils/local_state/useLocalStoredState';
import ButtonInfo from '../data/ButtonInfo';
import { ApplicationLinkAdaptation } from '../../../utils/link/link_utils';
import openLink from '../../../utils/link/openLink';
import CustomPaper from '../../../components/paper/CustomPaper';

const paperStyle = {
  padding: '24px',
  minWidth: '256px',
};

interface ContentViewHeaderProps {
  onReloadClick: () => void,
}

interface ContentViewInternalHeaderProps {
  currentLinkViewStyle: LinkViewStyle,
  toggleLinkViewStyle: () => void,
}

function ContentViewHeaderSettings(props: { isOpened: boolean } & ContentViewInternalHeaderProps) {
  const { isOpened, currentLinkViewStyle, toggleLinkViewStyle } = props;

  if (!isOpened) {
    return null;
  }

  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      <Grid item>
        <Typography variant="subtitle1">Детализированные ссылки</Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={currentLinkViewStyle === LinkViewStyle.Detailed}
          onChange={() => toggleLinkViewStyle()}
        />
      </Grid>
    </Grid>
  );
}

function ContentViewHeader(props: ContentViewHeaderProps & ContentViewInternalHeaderProps) {
  const { onReloadClick, currentLinkViewStyle, toggleLinkViewStyle } = props;

  const [settingsOpened, setSettingsOpened] = useState(false);

  const toggleSettings = () => {
    setSettingsOpened(!settingsOpened);
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item container direction="row" alignItems="center" justifyContent="space-between">

        <Grid item container direction="row" spacing={1} xs="auto" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1" onClick={() => onReloadClick()}>Обновить</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onReloadClick()}>
              <CachedIcon fontSize="medium" />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item>
          <IconButton onClick={() => toggleSettings()}>
            <Settings fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <ContentViewHeaderSettings
          toggleLinkViewStyle={toggleLinkViewStyle}
          currentLinkViewStyle={currentLinkViewStyle}
          isOpened={settingsOpened}
        />
      </Grid>
    </Grid>
  );
}

function CommonContentViewCard(
  props: { children: any } & ContentViewHeaderProps & ContentViewInternalHeaderProps,
) {
  const {
    children, currentLinkViewStyle, onReloadClick, toggleLinkViewStyle,
  } = props;

  return (
    <CustomPaper style={paperStyle} variant="outlined" sx={{ width: '100%' }}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ContentViewHeader
            toggleLinkViewStyle={toggleLinkViewStyle}
            onReloadClick={onReloadClick}
            currentLinkViewStyle={currentLinkViewStyle}
          />
        </Grid>
        { children }
      </Grid>
    </CustomPaper>
  );
}

interface ContentViewProps {
  data: DownloadedData<ButtonInfo[]>,
}

function ContentView(props: ContentViewProps & ContentViewHeaderProps) {
  const { data, onReloadClick } = props;

  const [linkViewStyle, setLinkViewStyle] = useLocalStoredState<LinkViewStyle>('home_screen_links_style', 'links_style', LinkViewStyle.Compact);
  const [selectedTabId, setSelectedTabId] = useLocalStoredState<TabId>('home_screen_tabs_view', 'selectedTabId', 'maps');

  const toggleLinkViewStyle = () => {
    setLinkViewStyle(
      linkViewStyle === LinkViewStyle.Compact
        ? LinkViewStyle.Detailed
        : LinkViewStyle.Compact,
    );
  };

  if (data.value.length === 0) {
    return (
      <CommonContentViewCard
        onReloadClick={onReloadClick}
        currentLinkViewStyle={linkViewStyle}
        toggleLinkViewStyle={toggleLinkViewStyle}
      >
        <Grid item>
          <Typography variant="h3">У тебя пока нет ссылок</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">Нужно перейти в бота чтобы создать</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => openLink('https://t.me/intents_helper_bot')}
          >
            Перейти в бота
          </Button>
        </Grid>
      </CommonContentViewCard>
    );
  }

  const tabsViewProps: TabsViewProps = {
    selectedId: selectedTabId,
    setSelectedId(id: TabId): void {
      setSelectedTabId(id);
    },
    data: [
      {
        id: 'maps',
        title: 'Карты',
      },
      {
        id: 'navi',
        title: 'Нави',
      },
      {
        id: 'yango',
        title: 'Yango',
      },
    ],
    disabled: false,
  };

  const linkAdaptation = (() => {
    switch (selectedTabId) {
      case 'maps': return ApplicationLinkAdaptation.YandexMaps;
      case 'navi': return ApplicationLinkAdaptation.NaviMaps;
      case 'yango': return ApplicationLinkAdaptation.Yango;
      default: return ApplicationLinkAdaptation.None;
    }
  })();

  return (
    <CommonContentViewCard
      onReloadClick={onReloadClick}
      currentLinkViewStyle={linkViewStyle}
      toggleLinkViewStyle={toggleLinkViewStyle}
    >
      <Grid item>
        <TabsView
          selectedId={tabsViewProps.selectedId}
          setSelectedId={tabsViewProps.setSelectedId}
          data={tabsViewProps.data}
          disabled={tabsViewProps.disabled}
        />
      </Grid>
      {
        data.value.map(((elem, index) => (
          <Grid item key={JSON.stringify(elem)}>
            { index !== 0 && linkViewStyle === LinkViewStyle.Detailed && <hr /> }
            <LinkView buttonInfo={elem} style={linkViewStyle} adaptation={linkAdaptation} />
          </Grid>
        )))
      }
    </CommonContentViewCard>
  );
}

export default ContentView;
